import { Injectable } from '@angular/core';
import { ICoreState } from '@app/core/+state/core.reducer';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { GetAddonCategoriesResponse } from '@app/models';
import { CreateAddonCategoryResponse } from '@app/models/responses/create-addon-category-response';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { AddonCategoriesService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as featureActions from './actions';

@Injectable()
export class AddonCategoriesStoreEffects {
  constructor(
    private dataService: AddonCategoriesService,
    private actions$: Actions,
    private store: Store<{ core: ICoreState }>,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadRequestAction,
          { core: ICoreState },
        ]) => {
          let propertiesIds = action.payload.propertyId;
          if (!propertiesIds) {
            propertiesIds = store.core.allProperties.map(({ id }) => id);
          }
          return this.dataService.load(propertiesIds).pipe(
            map(
              ({ data }: GetAddonCategoriesResponse) =>
                new featureActions.LoadSuccessAction({
                  items: data,
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.LoadFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.CreateRequestAction,
          { core: ICoreState },
        ]) => {
          let propertiesIds = action.payload.request.propertiesIds;
          if (!propertiesIds) {
            propertiesIds = store.core.allProperties.map(({ id }) => id);
          }
          const request = {
            ...action.payload.request,
            propertyId: propertiesIds,
          };
          return this.dataService.create(request).pipe(
            map(({ data }: CreateAddonCategoryResponse) => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.create_success',
                  {
                    param: this.translate.instant('addon_categories'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.CreateSuccessAction({
                item: data[0],
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.CreateFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.DeleteRequestAction,
          { core: ICoreState },
        ]) => {
          const { addonCategoryId } = action.payload;
          return this.dataService.delete(addonCategoryId).pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('addon_categories'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.DeleteSuccessAction({
                addonCategoryId,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([{ payload }, store]: [
          featureActions.UpdateRequestAction,
          { core: ICoreState },
        ]) => {
          const { request } = payload;
          const { languages } = request;
          const translations = Object.keys(request.languages);
          return this.dataService.update(request).pipe(
            map((response: IResponseSuccess) => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('addon_categories'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.UpdateSuccessAction({
                addonCategory: {
                  ...response.data[0],
                  translations: translations.map((isoCode) => ({
                    title: languages[isoCode].title,
                    locale: isoCode,
                  })),
                },
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.UpdateFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );
}
