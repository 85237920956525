import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { AddonCategory } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectAddonCategoriesState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('addonCategories');

export const selectAllAddonCategoriesItems: (
  state: object,
) => AddonCategory[] = featureAdapter.getSelectors(selectAddonCategoriesState)
  .selectAll;

export const selectAddonCategoriesById = (id: string) =>
  createSelector(
    selectAllAddonCategoriesItems,
    (allAddonCategories: AddonCategory[]) => {
      if (allAddonCategories) {
        return allAddonCategories.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectAddonCategoriesError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectAddonCategoriesState,
  getError,
);

export const selectAddonCategoriesIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectAddonCategoriesState,
  getIsLoading,
);
