import { CreateAddonCategoryRequest } from '@app/models/requests/create-addon-category-request';
import { UpdateAddonCategoryRequest } from '@app/models/requests/update-addon-category-request';
import { Action } from '@ngrx/store';

import { AddonCategory } from '../../models';

export enum ActionTypes {
  LOAD_REQUEST = '[Addon Categories] Load Request',
  LOAD_FAILURE = '[Addon Categories] Load Failure',
  LOAD_SUCCESS = '[Addon Categories] Load Success',

  CREATE_REQUEST = '[Addon Categories] Create Request',
  CREATE_FAILURE = '[Addon Categories] Create Failure',
  CREATE_SUCCESS = '[Addon Categories] Create Success',

  DELETE_REQUEST = '[Addon Categories] Delete Request',
  DELETE_FAILURE = '[Addon Categories] Delete Failure',
  DELETE_SUCCESS = '[Addon Categories] Delete Success',

  UPDATE_REQUEST = '[Addon Categories] Update Request',
  UPDATE_FAILURE = '[Addon Categories] Update Failure',
  UPDATE_SUCCESS = '[Addon Categories] Update Success',

  RESET_STATE = '[Addon Categories] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyId?: number[] }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: AddonCategory[] }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(public payload: { request: CreateAddonCategoryRequest }) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { item: AddonCategory }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(
    public payload: { propertyId?: number[]; addonCategoryId: number },
  ) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { addonCategoryId: number }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(public payload: { request: UpdateAddonCategoryRequest }) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { addonCategory: AddonCategory }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | ResetSuccessAction;
